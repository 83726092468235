export default {
  path: "/websiteOperation",
  redirect: "/websiteOperationHome",
  meta: {
    icon: "website",
    title: "展会网站运营",
    showLink: true,
    rank: 5
  },
  children: [
    {
      path: "/websiteOperationHome",
      name: "WebsiteOperationHome",
      component: () =>
        import("@/views/websiteOperation/websiteOperationHome/index.vue"),
      meta: {
        title: "展会网站首页"
      }
    },
    {
      path: "/exhibit",
      name: "Exhibit",
      component: () => import("@/views/websiteOperation/exhibit/index.vue"),
      meta: {
        cache: true,
        title: "展会关于展览"
      },
      children: [
        {
          path: "/exhibit/details",
          name: "ExhibitDetails",
          component: () =>
            import(
              "@/views/websiteOperation/otherPages/aboutExhibitionsDetails.vue"
            ),
          meta: {
            title: "详情"
          }
        }
      ]
    },
    {
      path: "/anAssembly",
      name: "AnAssembly",
      component: () => import("@/views/websiteOperation/anAssembly.vue"),
      meta: {
        title: "展会关于大会"
      },
      children: [
        {
          path: "/anAssembly/details",
          name: "AnAssemblyDetails",
          component: () =>
            import(
              "@/views/websiteOperation/otherPages/aboutExhibitionsDetails.vue"
            ),
          meta: {
            title: "展会关于大会详情"
          }
        }
      ]
    },
    {
      path: "/downloadCenter",
      name: "DownloadCenter",
      component: () => import("@/views/websiteOperation/downloadCenter.vue"),
      meta: {
        title: "展会下载中心"
      }
    },
    {
      path: "/news",
      name: "News",
      component: () => import("@/views/websiteOperation/news.vue"),
      meta: {
        title: "展会新闻资讯"
      },
      children: [
        {
          path: "/news/newsAdd",
          name: "NewsAdd",
          component: () =>
            import("@/views/websiteOperation/components/exhibitionForm.vue"),
          meta: {
            title: "展会新闻资讯更新新闻"
          }
        }
      ]
    },
    {
      path: "/expoNews",
      name: "ExpoNews",
      component: () => import("@/views/websiteOperation/expoNews.vue"),
      meta: {
        title: "展会新闻"
      },
      children: [
        {
          path: "/expoNews/expoNewsAdd",
          name: "ExpoNewsAdd",
          component: () =>
            import("@/views/websiteOperation/components/expoNewsForm.vue"),
          meta: {
            title: "展会新闻更新新闻"
          }
        }
      ]
    },
    {
      path: "/contactUs",
      name: "ContactUs",
      component: () => import("@/views/websiteOperation/contactUs/enter.vue"),
      meta: {
        title: "展会联系我们"
      },
      children: [
        {
          path: "/contactUs/detail",
          name: "ContactUsDetail",
          component: () =>
            import("@/views/websiteOperation/contactUs/detail.vue"),
          meta: {
            title: "展会联系我们详情",
            showLink: false
          }
        }
      ]
    },
    {
      path: "/unionWebsiteOperationHome",
      name: "UnionWebsiteOperationHome",
      component: () =>
        import("@/views/websiteOperation/unionWebsiteOperationHome/index.vue"),
      meta: {
        title: "联盟会员"
      },
      children: [
        {
          // 网站首页详情
          path: "/unionWebsiteOperationHomeDetails",
          name: "UnionWebsiteOperationHomeDetails",
          component: () =>
            import(
              "@/views/websiteOperation/unionWebsiteOperationHome/details.vue"
            ),
          meta: {
            cache: true,
            title: "联盟网站首页详情"
          }
        }
      ]
    },
    {
      path: "/hotRecruitmentPositions",
      name: "HotRecruitmentPositions",
      component: () =>
        import("@/views/websiteOperation/hotRecruitmentPositions/index.vue"),
      meta: {
        title: "联盟热招岗位"
      }
    },
    {
      path: "/federation",
      name: "Federation",
      component: () => import("@/views/websiteOperation/federation/enter.vue"),
      meta: { title: "储能领跑者联盟联合会官网" },
      children: [
        {
          path: "/federation/addHtml",
          name: "FederationAddHtml",
          component: () =>
            import(
              "@/views/websiteOperation/federation/federationHtml/federationHtml.vue"
            ),
          meta: { title: "添加" }
        },
        {
          path: "/federation/editHtml",
          name: "FederationEditHtml",
          component: () =>
            import(
              "@/views/websiteOperation/federation/federationHtml/federationHtml.vue"
            ),
          meta: { title: "编辑" }
        },
        {
          path: "/federation/details",
          name: "FederationDetails",
          component: () =>
            import("@/views/websiteOperation/federation/details/details.vue"),
          meta: { title: "详情" }
        }
      ]
    }
  ]
} as RouteConfigsTable;
